import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import {
  getActivePlayersByTournamentVariantId,
  getPlayersClean,
  updatePlayerInGame,
} from "../../../actions/playerActions";
import { getTournament } from "../../../actions/tournamentActions";
import OthersImage from "../../../image/icon-floorman.svg";
import PlayerActionsModal from "../../shared/PlayerActionsModal";
import TableSettingsModal from "./TableSettingsModal";
import { formatPlayerName, formatSeatNum, getPlayerState } from "../../../util/playerUtil";
import { arrayContainsSome } from "../../../util/stringUtil";
import ChoosePlayerModal from "./ChoosePlayerModal";
import SeatOpenModal from "./SeatOpenModal";
import useVariant from "../../useVariant";
import Loader from "../../shared/Loader";
import PlayerState from "../../../enum/PlayerState";
import moment from "moment-mini";
import { history } from "../../../service/history";
import restClient from "../../../util/restClient";
import RemoveSeatModal from "./RemoveSeatModal";
import socket from "../../../service/EvSocket";

const realoadOnActions = [
  "swapPlayersSeats",
  "reseatPlayer",
  "disqualifyPlayerFromVariant",
  "startUnregisterProcessPlayerFromVariant",
  "sitPlayerAndReturnToGameIfNeeded",
  "closePlayerInVariant",
];

const TableDetail = (props) => {
  const dispatch = useDispatch();

  const [forceSeatsReload, setForceSeatsReload] = useState("");
  const [forceVariantReload, setForceVariantReload] = useState("");
  const [modal, setModal] = useState(false);
  const [modalPlayerActions, setModalPlayerActions] = useState(false);
  const [modalChoosePlayer, setModalChoosePlayer] = useState(false);
  const [modalRemoveSeat, setModalRemoveSeat] = useState(false);
  const [modalSeatOpen, setModalSeatOpen] = useState(false);
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [origSelectedPlayer, setOrigSelectedPlayer] = useState(null);
  const [selectedFreeSeatNum, setSelectedFreeSeatNum] = useState(false);
  const [selectedSeatToRemove, setSelectedSeatToRemove] = useState(undefined);
  const tournament = useSelector((state) => state.tournaments.tournament || {});

  const [seatsData, setSeatsData] = useState([]);
  const seats = (seatsData.data || [])


  useEffect(() => {
    socket.on('seatsChange', 'TableDetail', (payload) => {
      if(Number(payload.gameTableId) === props.tableId) {
        restClient.getAsyncWrap(`/tournament/table/${props.tableId}/seats`, seatsData, setSeatsData)
      }
    });
    return () => {
      socket.removeHandler('seatsChange', 'TableDetail');
    };
  }, []);

  useEffect(() => {
    restClient.getAsyncWrap(`/tournament/table/${props.tableId}/seats`, seatsData, setSeatsData)
    dispatch(getPlayersClean());
    dispatch(getActivePlayersByTournamentVariantId(props.variantId));

  }, [forceSeatsReload])

  // if some of actions, which should cuase reload players appears in actionIdList, than we save that list, when this action dissapers from the list, players are reloaded
  const [newActionIdList, setNewActionIdList] = useState([]);
  useSelector((state) => {
    if (state.common.actionIdList !== newActionIdList) {
      setNewActionIdList(state.common.actionIdList);
    }
  });
  const prevActionListRef = useRef();
  useEffect(() => {
    prevActionListRef.current = newActionIdList;
  });
  const prevActionIdList = prevActionListRef.current;
  if (
    !arrayContainsSome(newActionIdList, realoadOnActions) &&
    arrayContainsSome(prevActionIdList, realoadOnActions)
  ) {
    prevActionListRef.current = [];
    dispatch(getPlayersClean());
    dispatch(getActivePlayersByTournamentVariantId(props.variantId));
    setForceVariantReload(moment().toDate().getTime().toString());
  }

  const variant = useVariant(props.variantId, "tableDetail", forceVariantReload);

  const allPlayers = useSelector((state) => state.player.playersInTournament.data || []);
  const players =
    allPlayers.filter(
      (player) => (player.stateId === 1 || player.stateId === 7) && player.tableId === props.tableId
    ) || [];

  const isPlayerInTournamentWithoutSeat = useSelector(
    (state) => state.player.playersInTournament.data || []
  ).some((player) => {
    return (
      getPlayerState(player) === PlayerState.NOT_SEATED.key ||
      getPlayerState(player) === PlayerState.WAITING_LIST.key
    );
  });

  const table = ((variant || {}).tables || []).find((item) => item.id === props.tableId) || {};

  useEffect(() => {
    dispatch(getPlayersClean());
    dispatch(getActivePlayersByTournamentVariantId(props.variantId));
    dispatch(getTournament(props.tournamentId));
  }, []);

  const togglePlayerActions = (customer) => {
    setModalPlayerActions(!modalPlayerActions);
    setSelectedPlayerId(customer ? customer.playerInTournamentPhaseVariantId : null);
    setOrigSelectedPlayer(null);
  };

  const toggleChoosePlayer = (seatNum) => {
    setModalChoosePlayer(!modalChoosePlayer);
    setSelectedPlayerId(null);
    setSelectedFreeSeatNum(seatNum);
  };

  const toggleRemoveSeat = (seatNum) => {
    setModalRemoveSeat(!modalRemoveSeat);
    setSelectedSeatToRemove(seatNum)
    if (modalRemoveSeat) {
      setForceSeatsReload(moment().toDate().getTime().toString())
    }
  };

  const toggleModalSeatOpen = (player) => {
    setSelectedPlayerId(player ? player.playerInTournamentPhaseVariantId : null);
    setModalSeatOpen(!modalSeatOpen);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const seatWithPlayersOnTable = seats.map(seat => {
    return { ...seat, player: players.find((p) => p.seatNum === seat.seatNum) };
  })

  const selectedPlayer = ((seatWithPlayersOnTable || []).find((each) => {
    return each && each.player && each.player.playerInTournamentPhaseVariantId === selectedPlayerId
  }) || {}).player;

  if (selectedPlayer && !origSelectedPlayer) {
    setOrigSelectedPlayer(selectedPlayer)
  } else if (
    selectedPlayer && origSelectedPlayer &&
    selectedPlayer.playerInTournamentPhaseVariantId === origSelectedPlayer.playerInTournamentPhaseVariantId &&
    JSON.stringify(selectedPlayer) != JSON.stringify(origSelectedPlayer)
  ) {
    const newOrigSelectedPlayers = { ...origSelectedPlayer, ...selectedPlayer }
    if (JSON.stringify(newOrigSelectedPlayers) !== JSON.stringify(origSelectedPlayer)) {
      setOrigSelectedPlayer({ ...origSelectedPlayer, ...selectedPlayer })
    }
  }

  const redirect = (path) => {
    history.push(path);
  };

  return (
    <>
      {modal && (
        <TableSettingsModal
          isOpen={modal}
          toggle={toggle}
          table={table}
          players={players}
          variant={variant}
          isPlayerInTournamentWithoutSeat={isPlayerInTournamentWithoutSeat}
        />
      )}
      <div className="p-5 d-none d-md-block" id="table-detail">
        <Row noGutters className="list-card-main-title">
          <Col>
            <img alt="title" className="list-card-main-title-icon" src={OthersImage} />
            <div className="list-card-main-title-text">
              {tournament.isMultiday ? variant.name : tournament.name}
            </div>
            <div className="list-card-main-title-statusBox-green">
              {variant.state ? variant.state.toUpperCase() : ""}
            </div>
          </Col>
        </Row>
        <Row noGutters>
          <Col xs={12}>
            <Row>
              <Col xs={9} className="my-auto">
                <h2 className="list-card-main-title-text">{`Table ${table.tableName ? table.tableName : ""
                  }`}</h2>
              </Col>
              <Col xs={3} c>
                <Button
                  onClick={() => {
                    toggle();
                  }}
                  className="fit no-wrap"
                  variant="outline-primary"
                >
                  Table settings
                  <FontAwesomeIcon icon={faCog} className="btn-icon" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="list-card-hr" />
        {newActionIdList.length > 0 ? (
          <Loader />
        ) : (
          <Row noGutters>
            {seatWithPlayersOnTable.map((seat, index) => {
              const player = seat.player;
              const seated = (player && player.seated === 1) ? true : false;
              return (
                <Col className="col-12 py-2" key={`col_${index}`}>
                  <Row className="table-detail-main-title no-wrap">
                    <div className="col1 mr-2">
                      <h3 style={{ marginBottom: 0 }}>
                        {seat.seatNum}
                      </h3>
                      Seat
                    </div>
                    {seat.active ? <>
                      <div className="col2 list-card-main-title-text my-auto mr-4">
                        <span>{player ? formatPlayerName(player) : "Free seat"}</span>
                      </div>
                      <div className="col3 my-auto">
                        {player && seated && (
                          <Button className="btn m-1 fit" onClick={() => toggleModalSeatOpen(player)}>
                            Seat open
                          </Button>
                        )}

                        {player && !seated && (
                          <Button
                            className="btn-success m-1 fit"
                            onClick={() =>
                              dispatch(
                                updatePlayerInGame(player.playerInTournamentPhaseVariantId, true)
                              )
                            }
                          >
                            Sit in
                          </Button>
                        )}

                        {!player && (
                          <>
                            <Button
                              className="btn-success m-1 fit"
                              onClick={() => toggleChoosePlayer(index + 1)}
                            >
                              Choose player
                            </Button>
                            <Button
                              className="fit m-1 no-wrap"
                              variant="outline-primary"
                              onClick={() => toggleRemoveSeat(index + 1)}
                            >
                              Remove seat
                            </Button>
                          </>
                        )}

                        {player && (
                          <Button
                            className="fit m-1 no-wrap"
                            variant="outline-primary"
                            onClick={() => {
                              togglePlayerActions(player);
                            }}
                          >
                            More...
                          </Button>
                        )}
                      </div>
                    </> : null}
                  </Row>
                </Col>
              )
            }
            )}
          </Row>
        )}
        {modalPlayerActions && (
          <PlayerActionsModal
            isOpen={modalPlayerActions}
            toggle={togglePlayerActions}
            player={selectedPlayer || origSelectedPlayer}
            players={players || {}}
            variant={variant}
          />
        )}
        {modalChoosePlayer && (
          <ChoosePlayerModal
            isOpen
            toggle={toggleChoosePlayer}
            variant={variant}
            tableId={props.tableId}
            seatNum={selectedFreeSeatNum}
          />
        )}
        {modalRemoveSeat && (
          <RemoveSeatModal
            isOpen
            toggle={toggleRemoveSeat}
            tableId={props.tableId}
            seatNum={selectedSeatToRemove}
          />
        )}
        {modalSeatOpen && (
          <SeatOpenModal
            isOpen
            toggle={toggleModalSeatOpen}
            player={selectedPlayer}
            variant={variant}
          />
        )}
      </div>
      <div className="d-block d-md-none" id="table-detail">
        <div className={"flex p-2"}>
          <div
            color="primary"
            onClick={() => {
              redirect(`/tournament/${sessionStorage.getItem("tournamentId")}`);
            }}
          >
            <p className="text-uppercase">
              {" "}
              <span style={{ color: "#5b38b9" }}>{`<`}</span>
              {` All Tables`}
            </p>
          </div>
        </div>
        <Row>
          <Col>
            <Row>
              <Col xs={4} className="my-auto">
                <h2 className="list-card-main-title-text">{`Table ${table.tableName ? table.tableName : ""
                  }`}</h2>
              </Col>
              <Col xs={3}>
                <Button
                  onClick={() => {
                    toggle();
                  }}
                  className="fit no-wrap"
                  variant="outline-primary"
                >
                  Table settings
                  <FontAwesomeIcon icon={faCog} className="btn-icon" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="list-card-hr" />
        {newActionIdList.length > 0 ? (
          <Loader />
        ) : (
          <Row noGutters>
            {seatWithPlayersOnTable.map((seat, index) => {
              const player = seat.player;
              return <Col xs={12} className={"p-2"} key={`col_${index}`}>
                <Row className="table-detail-main-title ">
                  <Col xs={1} className="m-2">
                    <h2 style={{ marginBottom: 0 }}>
                      {seat.seatNum}
                    </h2>
                    Seat
                  </Col>
                  {seat.active ? <Col xs={8}>
                    <Row>
                      <Col xs={12} className="list-card-main-title-text my-auto mr-4">
                        <span>{player ? formatPlayerName(player) : "Free seat"}</span>
                      </Col>
                      <Col xs={12} className="my-auto flex">
                        <Row>
                          {player && player.seated && (
                            <Col xs={8}>
                              <Button
                                className="m-1"
                                size="xs"
                                onClick={() => toggleModalSeatOpen(player)}
                              >
                                Seat open
                              </Button>
                            </Col>
                          )}

                          {player && !player.seated && (
                            <Col xs={6}>
                              <Button
                                className="btn-success m-1"
                                onClick={() =>
                                  dispatch(
                                    updatePlayerInGame(
                                      player.playerInTournamentPhaseVariantId,
                                      true
                                    )
                                  )
                                }
                              >
                                Sit in A
                              </Button>
                            </Col>
                          )}

                          {!player && (
                            <>
                              <Col xs={8}>
                                <Button
                                  className="btn-success m-1"
                                  size="xs"
                                  onClick={() => toggleChoosePlayer(index + 1)}
                                >
                                  Choose player
                                </Button>
                              </Col>
                              <Col xs={4}>
                                <Button
                                  className="fit m-1 no-wrap pr-2 pl-2"
                                  variant="outline-primary"
                                  onClick={() => toggleRemoveSeat(index + 1)}
                                >
                                  Remove seat
                                </Button>
                              </Col>
                            </>
                          )}

                          {player && (
                            <Col xs={2}>
                              <Button
                                className="fit m-1 no-wrap"
                                variant="outline-primary"
                                onClick={() => {
                                  togglePlayerActions(player);
                                }}
                              >
                                More...
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col> : null}
                </Row>
              </Col>
            })}
          </Row>
        )}
      </div>
    </>
  );
};

export default TableDetail;

import React from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment-mini";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSortDown,
  faSortUp,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons/index";
import { Button } from "reactstrap";
import { roundTo2dec } from "../../../util/numUtil";
import { formatPlayerName } from "../../../util/playerUtil";

const PayoutTableContent = (props) => {
  const { isMultiSelection, isITM, showSeatOpenVariant } = props;
  let isFirstPlayerInListResolved = false;
  return (
    <>
      {(props.tournamentPayouts || []).map((each, index) => {
        const payoutValue = each.toPayout;
        const isPlayerOnPlace = each.playerId > 0;
        const isFirstPlayerInList = isPlayerOnPlace && !isFirstPlayerInListResolved;
        if (!isFirstPlayerInListResolved && isPlayerOnPlace) {
          isFirstPlayerInListResolved = true;
        }
        const payoutPrc = each.poolPrc !== null ? roundTo2dec(100 * each.poolPrc) : null; //isPlayerOnPlace ? displayNumber(roundTo2dec(100 * each.poolPrc)) : (each.toPayout ? itmPoolPerc(each.toPayout) : "");
        const { bonus } = each;
        const isPositionWithPayout =
          (each.id !== undefined || each._id !== undefined) && !each.toDelete;
        let state;
        if (isITM && isPlayerOnPlace) {
          if (each.payoutAmount != null) {
            state = <span className="fr-green">Paid</span>;
          } else if (each.finishTimeConfirmed && payoutValue >= 0) {
            state = (
              <>
                <FontAwesomeIcon icon={faExclamationTriangle} size="xs" />
                <span className="ml-1">Pending payout</span>
              </>
            );
          } else {
            state = (
              <Button
                className="btt-link fr-violet"
                style={{
                  position: "relative",
                  bottom: 0,
                }}
                onClick={() => {
                  props.onConfirmFinishTime(each.playerInTournamentPhaseVariantId);
                }}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} size="xs" />
                Confirm position
              </Button>
            );
          }
        } else {
          if (each.finishTimeConfirmed === 0) {
            state = (
              <Button
                className="btt-link fr-violet"
                style={{
                  position: "relative",
                  bottom: 0,
                }}
                onClick={() => {
                  props.onConfirmFinishTime(each.playerInTournamentPhaseVariantId);
                }}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} size="xs" />
                Confirm position
              </Button>
            );
          } else {
            state = "";
          }
        }

        const seatOpenValue = isPlayerOnPlace ? `${moment(each.originalFinishedAt).format("HH:mm:ss")}${showSeatOpenVariant ? " - " + each.variantName : ""}` : ""

        return (
          <Row noGutters className="my-1 py-1 parent-row">
            <Col xs={11} className="lightBox">
              <Row>
                <Col xs={1} className="py-2">{`${each.position}.`}</Col>
                <Col xs={3} className="py-2">
                  <span>{`${isPlayerOnPlace ? formatPlayerName(each) : ""}`}</span>{" "}
                  {each.entryState === "reentry" && <span className="green-label">REENTRY</span>}
                </Col>
                <Col xs={props.isMobile ? 3 : 2} className="py-2">{seatOpenValue}</Col>
                <Col xs={props.isMobile ? 2 : 1} className="py-2">{isITM ? payoutPrc : ""}</Col>
                <Col xs={props.isMobile ? 2 : 1} className="py-2">{isITM ? payoutValue : ""}</Col>
                {!props.isMobile && <Col xs={2} className="py-2">{bonus}</Col>}
                <Col xs={isMultiSelection ? 1 : 2} className="py-2">
                  <div className="float-right">{state}</div>
                </Col>
                {!props.isMobile && isMultiSelection ? (
                  <Col xs={1} className="">
                    <input
                      type="checkbox"
                      className=""
                      id={`multiselect_${index}`}
                      checked={each.selected}
                      onChange={() => props.onSelectionChange(each)}
                    />
                  </Col>
                ) : null}
              </Row>
            </Col>
            <Col xs={1}>
              <Row style={{ flexWrap: "nowrap" }}>
                {!props.isMobile && <Col xs={3}>
                  {isITM && isPositionWithPayout && (
                    <FontAwesomeIcon
                      onClick={() => {
                        props.editItem(each);
                      }}
                      icon={faEdit}
                      size="lg"
                      className="colored"
                    />
                  )}
                </Col>}
                {isPlayerOnPlace && (
                  <>
                    <Col xs={3} className={props.isMobile ? "pr-0 mr-2" : ""}>
                      {!isFirstPlayerInList && <FontAwesomeIcon
                        onClick={() => {
                          props.updatePlayerPosition(
                            each.playerInTournamentPhaseVariantId,
                            each.tournamentId,
                            each.position - 1
                          );
                        }}
                        icon={faSortUp}
                        size={props.isMobile ? "1x" : "2x"}
                        className="colored"
                        underline-position="1"
                      />}
                    </Col>
                    <Col xs={3} className={props.isMobile ? "pr-0" : ""}>
                      <FontAwesomeIcon
                        onClick={() => {
                          props.updatePlayerPosition(
                            each.playerInTournamentPhaseVariantId,
                            each.tournamentId,
                            each.position + 1
                          );
                        }}
                        icon={faSortDown}
                        size={props.isMobile ? "1x" : "2x"}
                        className="colored-u"
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default PayoutTableContent;

import React, { useEffect, useState } from "react";
import { Alert, Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { Prompt } from "react-router-dom";
import { updateTournament } from "../../actions/tournamentActions";
import {
  clearPayout,
  clearPayoutRecalculation,
  getTournamentPayouts,
  payoutRecalculation,
  updateTournamentPayouts,
} from "../../actions/tournamentPayoutActions";
import { updatePlayerInGame, updatePlayerInGamePosition } from "../../actions/playerActions";
import useTournament from "../useTournament";
import { display } from "../../enum/Currency";
import Loader from "../shared/Loader";
import useTournamentStats from "../shared/useTournamentStats";
import MultilevelDropdown from "../shared/MultilevelDropdown";
import TournamentHeader from "../shared/TournamentHeader";
import socket from "../../service/EvSocket";
import { displayMoney } from "../../util/stringUtil";
import { constructEditMixin } from "../../util/tournamentUtil";

import EditPayoutItemModal from "./payouts/EditPayoutItemModal";
import PayoutSetupModal from "./payouts/PayoutSetupModal";
import PayoutTableContent from "./payouts/PayoutTableContent";
import SetNoImtPlayerModal from "./payouts/SetNoImtPlayerModal";
import PrizePoolModal from "./payouts/PrizePoolModal";
import {
  getTemplateById,
  getTemplatePayoutStructure,
  getTemplatePayoutStructureValues,
  getTemplates,
  insertTemplate,
} from "../../actions/templateActions";
import SaveTemplateModal from "./payouts/SaveTemplateModal";

import TemplateType from "../../enum/TemplateType";
import { v4 as uuidv4 } from "uuid";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-mini";
import { isMobile } from 'react-device-detect';

const TEMPLATE_PAYOUT_STRUCTURE_TYPE = {
  SELF_CREATED: "SELF_CREATED",
  PREDEFINED: "PREDEFINED",
};

const Payouts = (props) => {
  const dispatch = useDispatch();

  const [showPrizePoolModal, setShowPrizePoolModal] = useState(false);
  const [showSetNoImtPlayerModal, setShowSetNoImtPlayerModal] = useState(false);
  const [showPayoutSetupModal, setShowPayoutSetupModal] = useState(false);
  const [showEditPayoutModal, setShowEditPayoutModal] = useState(false);
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isMultiSelection, setMultiSelection] = useState(false);
  const [selected, setSelected] = useState([]);
  const [editList, setEditList] = useState([]);
  const tournament = useTournament(props.id);

  const statistics = useTournamentStats(props.id);
  const totalPool = useSelector((state) => state.tournamentPayouts.totalPool);

  const playerPayouts = useSelector((state) => state.tournamentPayouts.tournamentPayouts);
  const payoutsRecalculation = useSelector((state) => state.tournamentPayouts.payoutsRecalculation);

  const predefinedTemplates = useSelector((state) => state.templates.payoutStructures);
  const customizedTemplates = useSelector((state) => state.templates.templates);

  const variantNames = {};
  if (tournament && tournament.tournamentPhases) {
    tournament.tournamentPhases.forEach(tp => tp.variants.forEach(v => variantNames[v.id] = v.name));
  }
  const dropDownItems = (predefinedTemplates || [])
    .map((predefinedTemplate) => {
      return {
        name: predefinedTemplate.label,
        subitems: (predefinedTemplate.payoutRedistribution || []).map((each) => {
          return {
            name: each.shortName,
            groupid: predefinedTemplate.groupId,
            type: TEMPLATE_PAYOUT_STRUCTURE_TYPE.PREDEFINED,
            template: each,
          };
        }),
      };
    })
    .concat([
      {
        name: "Self created",
        subitems:
          customizedTemplates.map((each) => ({
            name: each.name,
            type: TEMPLATE_PAYOUT_STRUCTURE_TYPE.SELF_CREATED,
            template: each,
          })) || [],
      },
    ]);

  const maxPosition = Math.max(
    ...[
      ...playerPayouts.map((each) => each.position || 0),
      ...editList.map((each) => each.position),
      0,
    ]
  );

  useEffect(() => {
    //fetch customized template
    dispatch(getTemplates(TemplateType.TOURNAMENT_PAYOUT));
    //fetch predefined templates
    dispatch(getTemplatePayoutStructure());
  }, []);

  const editMixin =
    maxPosition === 0
      ? []
      : constructEditMixin(editList, playerPayouts, payoutsRecalculation, maxPosition, variantNames, tournament.isMultiday);

  const payouts = [];
  const onlyPlayers = [];

  editMixin.forEach((each) => {
    if ((each.id !== undefined || each._id !== undefined) && !each.toDelete) {
      if (each.playerId) {
        if (!each.rebuyOf) {
          const numOfDuplicate = (
            payouts.filter((player) => each.playerId === player.playerId) || []
          ).length;
          if (numOfDuplicate === 0) {
            each.entryState = "buyIn";
            each.reEntryCount = 0;
          } else {
            const originalBuyIn = payouts.find(
              (player) => each.playerId === player.playerId && player.entryState === "buyIn"
            );
            if (originalBuyIn) {
              originalBuyIn.hasReentry = true;
            }
            each.entryState = "reentry";
            each.reEntryCount = numOfDuplicate - 1;
          }
        } else {
          each.entryState = "reBuy";
        }
      }
      payouts.push(each);
    } else {
      onlyPlayers.push(each);
    }
  });

  useEffect(() => {
    dispatch(getTournamentPayouts(props.id));
    socket.on("playersOnTableChange", "Payouts", () => {
      dispatch(updateTournamentPayouts(props.id));
    });
    socket.on("updateTournament", "Payouts", (payload) => {
      const tournamentId = payload.id;
      if (props.id === tournamentId) {
        dispatch(getTournamentPayouts(tournamentId));
      }
    });
    return () => {
      dispatch(clearPayoutRecalculation());
      dispatch(clearPayout());
      //dispatch(cleanTournament())
      socket.removeHandler("playersOnTableChange", "Payouts");
    };
  }, [props.id]);

  const selectFromDropdown = (item) => {
    let actionToFetchDetail;
    if (item.type === TEMPLATE_PAYOUT_STRUCTURE_TYPE.SELF_CREATED) {
      actionToFetchDetail = getTemplateById(item.template.id);
    } else {
      actionToFetchDetail = getTemplatePayoutStructureValues(item.groupid, item.template.id);
    }
    actionToFetchDetail(dispatch).then((response) => {
      const templateDetail = response.data;
      let content;
      if (item.type === TEMPLATE_PAYOUT_STRUCTURE_TYPE.SELF_CREATED) {
        content = JSON.parse(templateDetail.content);
      } else {
        content = templateDetail.content;
      }
      content.forEach((each) => {
        each._id = uuidv4();
      });
      const editListLength = Math.max(content.length, editList.length);
      const updatedEditList = [];
      for (let count = 0; count < editListLength; count++) {
        const position = count + 1;
        const contentItem = content.find((each) => each.position === position);
        const editListItem = editList.find((each) => each.position === position);
        const originalPayout = playerPayouts.find((each) => each.position === position) || {};

        updatedEditList.push({ ...editListItem, ...contentItem, id: originalPayout.id });
      }

      const newEditList = addRemoveItem(content.length, updatedEditList);

      invokeRecalculation(
        totalPool,
        tournament.numTopPlayersRedistributingRemnantPool,
        newEditList
      );
    });
  };

  const togglePrizePoolModal = () => {
    setShowPrizePoolModal(!showPrizePoolModal);
  };

  const togglePayoutSetupModal = () => {
    setShowPayoutSetupModal(!showPayoutSetupModal);
  };

  const toggleEditPayoutModal = () => {
    setMultiSelection(false);
    setShowEditPayoutModal(!showEditPayoutModal);
  };

  const toggleShowSaveTemplateModal = () => {
    setShowSaveTemplateModal(!showSaveTemplateModal);
  };

  const toggleSetNoImtPlayerModal = () => {
    setShowWarning(false);
    setShowSetNoImtPlayerModal(!showSetNoImtPlayerModal);
  };
  const toggleAssignSamePayoutToMultiPLayers = () => {
    setShowWarning(!payouts.length > 0);
    if (payouts.length > 0) {
      setMultiSelection(true);
      setShowPayoutSetupModal(false);
    }
  };

  const setNumberOfITMPlayers = (number) => {
    togglePayoutSetupModal();
    toggleSetNoImtPlayerModal();
    const newEditList = addRemoveItem(number, editList);
    invokeRecalculation(totalPool, tournament.numTopPlayersRedistributingRemnantPool, newEditList);
  };

  const clearAllPayouts = () => {
    const newEditList = addRemoveItem(0, editList);
    invokeRecalculation(totalPool, tournament.numTopPlayersRedistributingRemnantPool, newEditList);
  };

  const updatePlayerPosition = (playerInTournamentPhaseVariantId, tournamentId, position) => {
    dispatch(updatePlayerInGamePosition(playerInTournamentPhaseVariantId, tournamentId, position));
  };

  const confirmFinishTime = (id) => {
    dispatch(updatePlayerInGame(id, undefined, true));
  };

  const saveItems = () => {
    const tournamentId = props.id;
    const payoutsForUpdate = editList.filter((each) => each.id > 0 || !each.toDelete);
    dispatch((d) => {
      const updateTournamentAction = updateTournament({
        id: tournamentId,
        payoutStructure: payoutsForUpdate,
      });
      updateTournamentAction(d).then(() => {
        setEditList([]);
      });
    });
  };

  const addRemoveItem = (newMaxPosition, editList) => {
    const iterationCount = Math.max(
      ...[
        ...playerPayouts.map((each) => each.position || 0),
        ...editList.map((each) => each.position),
        newMaxPosition,
      ]
    );
    const editListCopy = [...editList];

    for (let position = 1; position <= iterationCount; position++) {
      const payout = editMixin.find((each) => each.position === position);
      if (position <= newMaxPosition) {
        const editItem = editListCopy.find((each) => each.position === position);
        // keep item
        if (editItem) {
          editItem.toDelete = false;
        } else {
          editListCopy.push({
            _id: moment().toDate().getTime() + Math.random(),
            poolPrc: null,
            ...payout,
            position,
            tournamentId: props.id,
          });
        }
      } else {
        // remove item
        // eslint-disable-next-line no-lonely-if
        if (payout.isEditItem) {
          const indexEdit = editListCopy.findIndex((each) => each.position === position);
          const indexOriginalPayout = playerPayouts.findIndex((each) => each.position === position);
          if (indexOriginalPayout >= 0) {
            editListCopy[indexEdit] = {
              ...payout,
              toDelete: true,
              tournamentId: props.id,
            };
          } else {
            editListCopy.splice(indexEdit, 1);
          }
        } else {
          editListCopy.push({
            _id: moment().toDate().getTime() + Math.random(),
            ...payout,
            toDelete: true,
            tournamentId: props.id,
          });
        }
      }
    }
    const newEditList = editListCopy.sort((a, b) => a.position - b.position);
    setEditList(newEditList);
    return newEditList;
    // dispatch(payoutRecalculation(totalPool, tournament.numTopPlayersRedistributingRemnantPool, payouts));
  };

  const invokeRecalculation = (totalPool, numTopPlayersRedistributingRemnantPool, newEditList) => {
    dispatch(
      payoutRecalculation(
        totalPool,
        tournament.numTopPlayersRedistributingRemnantPool,
        constructEditMixin(newEditList, playerPayouts, payoutsRecalculation, maxPosition).filter(
          (each, index) => {
            return !each.toDelete && (each.poolPrc !== undefined || each.bonus !== undefined);
          }
        )
      )
    );
  };

  const sumToPay = payouts.reduce(
    (accumulator, currentValue) =>
      accumulator + (currentValue.toPayout !== undefined ? currentValue.toPayout : 0),
    0
  );

  /*Number close to 100% shows rather as 99,99% or 100,01%, only exactly 100 as 100%*/
  const getAllocatedPrcValue = () => {
    const value = (100 * sumToPay) / totalPool;
    if (value > 99.99 && value <= 100.01) {
      const precision = 0.00001;
      if (Math.abs(value - 100) > precision) {
        // is not 100%
        if (value > 100) {
          return 100.01;
        } else {
          return 99.99;
        }
      }
    }
    return value.toFixed(2);
  };

  const getAllocatedPrc = () => {
    const value = getAllocatedPrcValue();
    return `${value} %`;
  };

  const sumAlreadyPaid = payouts
    .filter((each) => each.payoutAmount > 0)
    .reduce((accumulator, currentValue) => accumulator + currentValue.payoutAmount, 0);
  const selectedPosition = selected[0] || {};
  const itemToEdit = payouts.find((each) => each.position === selectedPosition);

  return (
    <>
      <div id="editPayouts">
        <Row className="parent-row">
          <Col>
            <TournamentHeader tournament={tournament} />
          </Col>
        </Row>

        <div className="payout-body">
          <Container className="payout-body-content">
            {payouts.length === 0 && (
              <Row className="parent-row">
                <Col className="no-paddings">
                  <Alert variant="success">
                    <p>
                      {"Please set No. Of ITM players in payout setup or load payout from template"}
                    </p>
                  </Alert>
                </Col>
              </Row>
            )}
            <Row className="mb-4">
              <Col xs={isMobile ? 7 : 5}>
                <h1>Payout structure</h1>
              </Col>
              {!isMobile && <Col xs={2}>
                <Button
                  color="primary"
                  onClick={togglePayoutSetupModal}
                  style={{ visibility: isMultiSelection ? "hidden" : "visible" }}
                >
                  Payout setup
                  <FontAwesomeIcon icon={faCog} className="btn-icon white" />
                </Button>
              </Col>}
              <Col xs={isMobile ? 5 : 2}>
                <Button
                  style={{ visibility: isMultiSelection ? "hidden" : "visible", fontSize: "0.8rem" }}
                  color="primary"
                  className="black"
                  onClick={togglePrizePoolModal}
                >
                  Collected prize pool
                </Button>
              </Col>
              {!isMobile && <Col>
                <MultilevelDropdown items={dropDownItems} select={selectFromDropdown} />
              </Col>}
            </Row>
            <div className="graySeparator" />
            <Row className="parent-row">
              <Col xs={11} className="lightBox ">
                <Row>
                  <Col className="no-wrap py-2" xs={1}>#</Col>
                  <Col className="no-wrap py-2" xs={isMobile ? 2 : 3}>Name</Col>
                  <Col className={isMobile ? "py-2" : "no-wrap py-2"} xs={isMobile ? 3 : 2}>Seat open at</Col>
                  <Col className={isMobile ? "py-2" : "no-wrap py-2"} xs={isMobile ? 2 : 1}>Payout %</Col>
                  <Col className={isMobile ? "py-2" : "no-wrap py-2"} xs={isMobile ? 2 : 1}>{`Payout ${display(tournament.currency || "")}`}</Col>
                  {!isMobile && <Col className={isMobile ? "py-2" : "no-wrap py-2"} xs={2}>Text to price</Col>}
                  <Col className="no-wrap py-2" xs={2} />
                </Row>
              </Col>
            </Row>

            {payouts.length > 0 && (
              <>
                <PayoutTableContent
                  isMobile={isMobile}
                  allocatedPerc={getAllocatedPrcValue()}
                  allocatedValue={sumToPay}
                  isITM={true}
                  tournamentPayouts={payouts}
                  updatePlayerPosition={updatePlayerPosition}
                  isMultiSelection={isMultiSelection}
                  editItem={(item) => {
                    setSelected([item.position]);
                    toggleEditPayoutModal();
                  }}
                  onSelectionChange={(payout) => {
                    const selectedCopy = [...selected];
                    const selectedIndex = selectedCopy.findIndex(
                      (each) => payout.position === each
                    );
                    if (selectedIndex >= 0) {
                      selectedCopy.splice(selectedIndex, 1);
                    } else {
                      selectedCopy.push(payout.position);
                    }
                    setSelected(selectedCopy);
                  }}
                  onConfirmFinishTime={(id) => {
                    if (id) {
                      confirmFinishTime(id);
                    }
                  }}
                />

                <Row className="parent-row">
                  <Col xs={11}>
                    <Row className="blueBox mb-2">
                      <Col xs={isMobile ? 4 : 6} className="my-1 py-1">
                        Allocated:
                      </Col>
                      <Col xs={isMobile ? 4 : 1} className="my-1 py-1 pr-0">
                        {getAllocatedPrc()}
                      </Col>
                      <Col xs={isMobile ? 4 : 5} className="my-1 py-1">
                        {`${displayMoney(sumToPay, tournament.currency)}`}
                        {sumToPay > totalPool && (
                          <span style={{ color: "red", marginLeft: "13px" }}>
                            ({displayMoney(sumToPay - totalPool, tournament.currency)})
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Row className="blueBox">
                      <Col xs={isMobile ? 8 : 6} className="my-1 py-1">
                        Prize pool already paid:
                      </Col>
                      <Col xs={isMobile ? 4 : 1} className="my-1 py-1">
                        {`${(displayMoney(sumAlreadyPaid), tournament.currency)}`}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="parent-row mt-4"><h3>Non ITM players</h3></Row>
                <div className="graySeparator" />
              </>
            )}
            <PayoutTableContent
              isMobile={isMobile}
              isITM={false}
              tournamentPayouts={onlyPlayers}
              showSeatOpenVariant={tournament.isMultiday}
              updatePlayerPosition={updatePlayerPosition}
              onConfirmFinishTime={(id) => {
                if (id) {
                  confirmFinishTime(id);
                }
              }}
            />

            {showPrizePoolModal && (
              <PrizePoolModal
                isMobile={isMobile}
                isOpen={showPrizePoolModal}
                totalPool={totalPool}
                tournament={tournament}
                toggle={togglePrizePoolModal}
                statistics={statistics}
                onSavePrizePoolField={(obj) => {
                  dispatch((d) => {
                    const updateTournamentAction = updateTournament(obj);
                    updateTournamentAction(d).then(() => {
                      invokeRecalculation(
                        obj.adjustedPool,
                        tournament.numTopPlayersRedistributingRemnantPool,
                        editList
                      );
                    });
                  });
                }}
                onSaveNumTopPlayersRedistributingRemnantPool={(
                  id,
                  numTopPlayersRedistributingRemnantPool
                ) => {
                  dispatch((d) => {
                    const updateTournamentAction = updateTournament({
                      id,
                      numTopPlayersRedistributingRemnantPool,
                    });
                    updateTournamentAction(d).then(() => {
                      invokeRecalculation(
                        statistics.prizePool,
                        tournament.numTopPlayersRedistributingRemnantPool,
                        editList
                      );
                    });
                  });
                }}
              />
            )}
            {showPayoutSetupModal && (
              <PayoutSetupModal
                showWarning={showWarning}
                isOpen={showPayoutSetupModal}
                toggle={togglePayoutSetupModal}
                onSetNoITMPlayers={toggleSetNoImtPlayerModal}
                onAssignSamePayoutToMultiPLayers={toggleAssignSamePayoutToMultiPLayers}
              />
            )}
            {showEditPayoutModal && (
              <EditPayoutItemModal
                isOpen={showEditPayoutModal}
                currency={tournament.currency}
                totalPool={totalPool}
                toggle={toggleEditPayoutModal}
                editItem={itemToEdit}
                onEdit={(item) => {
                  const { id, _id, position, toPayout, isEditItem, ...payoutValue } = item;
                  if (!(payoutValue.poolPrc >= 0)) {
                    payoutValue.poolPrc = null;
                  }
                  if (payoutValue.fixedValue === null || !(payoutValue.fixedValue >= 0)) {
                    payoutValue.fixedValue = null;
                  } else {
                    if (totalPool > 0) {
                      //for now, we dont fix the value - rather calculate relative payout to pool
                      payoutValue.poolPrc = payoutValue.poolPrc
                        ? payoutValue.poolPrc
                        : payoutValue.fixedValue / totalPool;
                      payoutValue.fixedValue = null;
                    }
                  }
                  const copy = [...editList];
                  selected.forEach((eachSelectedPosition) => {
                    const index = editList.findIndex(
                      (each) => each.position === eachSelectedPosition
                    );
                    const selectedItem = payouts.find(
                      (each) => each.position === eachSelectedPosition
                    );
                    if (index >= 0) {
                      copy[index] = {
                        id: selectedItem.id,
                        _id: selectedItem._id,
                        ...payoutValue,
                        position: eachSelectedPosition,
                      };
                    } else {
                      copy.push({
                        id: selectedItem.id,
                        _id: selectedItem._id,
                        ...payoutValue,
                        position: eachSelectedPosition,
                      });
                    }
                  });
                  const newEditList = copy.sort((a, b) => a.position - b.position);
                  setEditList(newEditList);
                  setSelected([]);
                  toggleEditPayoutModal();
                  invokeRecalculation(
                    totalPool,
                    tournament.numTopPlayersRedistributingRemnantPool,
                    newEditList
                  );
                }}
              />
            )}
            {showSaveTemplateModal && (
              <SaveTemplateModal
                isOpen={showSaveTemplateModal}
                onCancel={toggleShowSaveTemplateModal}
                onConfirm={(templateName) => {
                  const forSave = payouts.map((each) => ({
                    position: each.position,
                    poolPrc: each.poolPrc,
                    bonus: each.bonus,
                  }));
                  dispatch(insertTemplate("tournament_payout", templateName, forSave));
                  toggleShowSaveTemplateModal();
                }}
              />
            )}
            {showSetNoImtPlayerModal && (
              <SetNoImtPlayerModal
                noImtPlayers={payouts.length}
                isOpen={showSetNoImtPlayerModal}
                toggle={toggleSetNoImtPlayerModal}
                saveNoImtPlayerModal={setNumberOfITMPlayers}
              />
            )}
          </Container>
        </div>
        {!isMobile && <div className="confirm-buttons content-start">
          {!isMultiSelection && payouts.length > 0 && (
            <Button color="primary fit no-wrap" onClick={toggleShowSaveTemplateModal}>
              Save as new template
            </Button>
          )}
          {!isMultiSelection && editList.length === 0 && payouts.length > 0 && (
            <Button color="primary" className="black fit no-wrap" onClick={clearAllPayouts}>
              Clear all payouts
            </Button>
          )}

          {(editList.length > 0 || isMultiSelection) && (
            <>
              {!isMultiSelection && (
                <>
                  <Button
                    color="primary"
                    className="black fit  no-wrap"
                    onClick={() => {
                      setSelected([]);
                      setEditList([]);
                      dispatch(clearPayoutRecalculation());
                    }}
                  >
                    Clear changes
                  </Button>
                  <div style={{ width: "100%" }} />
                  <Button color="secondary" className="fit  no-wrap" onClick={saveItems}>
                    Use this payout
                  </Button>
                </>
              )}
              {isMultiSelection && (
                <>
                  <Button
                    color="primary"
                    className="fit"
                    onClick={(e) => {
                      setMultiSelection(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <div style={{ width: "100%" }} />
                  <Button
                    color="secondary"
                    className="fit no-wrap"
                    disabled={selected.length === 0}
                    onClick={() => {
                      toggleEditPayoutModal();
                    }}
                  >
                    Change values
                  </Button>
                </>
              )}
            </>
          )}
        </div>}
      </div>
    </>
  );
};

export default Payouts;
